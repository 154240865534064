.upcoming-contests {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.contests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.last-updated {
  font-size: 0.9rem;
  color: #666;
}

.platform-filter select {
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
}

.platform-filter select:hover {
  border-color: #999;
}

.contests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.contest-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.contest-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contest-platform {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.contest-card h3 {
  margin: 10px 0;
  color: #333;
  font-size: 1.2rem;
  line-height: 1.4;
}

.contest-details {
  margin: 15px 0;
  flex-grow: 1;
}

.contest-details p {
  margin: 8px 0;
  color: #666;
}

.time-until {
  color: #e67e22 !important;
  font-weight: 500;
  margin-top: 12px !important;
}

.register-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s;
  text-align: center;
  margin-top: auto;
  font-weight: 500;
}

.register-button:hover {
  background-color: #45a049;
}

.contests-loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.contests-error {
  text-align: center;
  padding: 40px;
  color: #e74c3c;
}

.retry-button {
  margin-top: 15px;
  padding: 8px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: #2980b9;
}

.no-contests {
  text-align: center;
  padding: 40px;
  color: #666;
  background: #f9f9f9;
  border-radius: 10px;
  margin-top: 20px;
}

.check-back {
  color: #999;
  font-size: 0.9rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .contests-grid {
    grid-template-columns: 1fr;
  }
  
  .contest-card {
    margin: 10px 0;
  }

  .contests-header {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }

  .header-main {
    align-items: center;
  }
}

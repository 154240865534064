.progress-container {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.progress-header {
  margin-bottom: 30px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stat-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.stat-title {
  color: #6b7280;
  font-size: 14px;
  margin-bottom: 8px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #1f2937;
}

.progress-chart {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 30px;
}

.platform-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.platform-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.platform-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.platform-name {
  font-size: 18px;
  font-weight: 500;
  color: #1f2937;
}

.solved-count {
  background: #f3f4f6;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #4b5563;
}

.difficulty-bars {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.difficulty-bar {
  background: #f3f4f6;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
}

.bar-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.bar-fill.easy {
  background-color: #34d399;
}

.bar-fill.medium {
  background-color: #fbbf24;
}

.bar-fill.hard {
  background-color: #ef4444;
}

.difficulty-label {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #6b7280;
}

.controls {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.timeframe-select,
.metric-select {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  font-size: 0.95rem;
  cursor: pointer;
  min-width: 150px;
}

.timeframe-select:focus,
.metric-select:focus {
  outline: none;
  border-color: #667eea;
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.1);
}

.chart-container {
  position: relative;
  height: 400px;
  width: 100%;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  font-size: 1.1rem;
  color: #4a5568;
}

.error {
  background-color: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
}

.retry-button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  background: #667eea;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background: #5a67d8;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    45deg,
    #FF3366,
    #FF33FF,
    #3333FF,
    #33FFFF
  );
  background-size: 300% 300%;
  animation: gradientFlow 20s ease infinite;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Shimmering overlay */
.auth-container::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 25%,
    transparent 50%
  );
  animation: shimmer 15s linear infinite;
  transform-origin: center;
}

@keyframes shimmer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Glowing particles */
.light-beam {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.1) 0%, transparent 10%),
    radial-gradient(circle at 80% 70%, rgba(255, 255, 255, 0.1) 0%, transparent 10%),
    radial-gradient(circle at 40% 80%, rgba(255, 255, 255, 0.1) 0%, transparent 10%),
    radial-gradient(circle at 60% 20%, rgba(255, 255, 255, 0.1) 0%, transparent 10%);
  animation: glow 8s ease-in-out infinite alternate;
  pointer-events: none;
}

@keyframes glow {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

.auth-card {
  position: relative;
  z-index: 3;
  background: rgba(255, 255, 255, 0.9);
  padding: 3rem;
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
  box-shadow: 
    0 20px 50px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  transform: translateY(0);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.auth-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 25px 60px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.6),
    0 0 30px rgba(255, 255, 255, 0.3);
}

.auth-card h2 {
  text-align: center;
  background: linear-gradient(45deg, #FF3366, #3333FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 2rem;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2d3748;
  font-size: 0.95rem;
  font-weight: 600;
}

.form-group input {
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(51, 51, 255, 0.2);
  border-radius: 12px;
  font-size: 1rem;
  color: #1a202c;
  transition: all 0.3s ease;
}

.form-group input:disabled {
  background: rgba(255, 255, 255, 0.7);
  cursor: not-allowed;
}

.form-group input::placeholder {
  color: #a0aec0;
}

.form-group input:focus {
  outline: none;
  border-color: #3333FF;
  background: white;
  box-shadow: 0 0 0 4px rgba(51, 51, 255, 0.1);
}

.auth-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, #FF3366, #3333FF);
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.auth-button:disabled {
  background: linear-gradient(45deg, #999, #666);
  cursor: not-allowed;
  transform: none;
  opacity: 0.7;
}

.auth-button:not(:disabled):hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255, 51, 102, 0.3);
}

.auth-button:active {
  transform: translateY(0);
}

.auth-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shine 1.5s infinite;
}

.auth-button:disabled::before {
  animation: none;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.auth-links {
  margin-top: 1.5rem;
  text-align: center;
}

.auth-links p {
  margin: 0.75rem 0;
  color: #2d3748;
  font-size: 0.95rem;
}

.auth-links a {
  color: #3333FF;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  position: relative;
}

.auth-links a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #FF3366, #3333FF);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.auth-links a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.success-message {
  background: rgba(72, 187, 120, 0.1);
  color: #2f855a;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.95rem;
  border: 1px solid rgba(72, 187, 120, 0.2);
  backdrop-filter: blur(5px);
}

.error-message {
  background: rgba(245, 101, 101, 0.1);
  color: #c53030;
  padding: 1rem;
  border-radius: 12px;
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 0.95rem;
  border: 1px solid rgba(245, 101, 101, 0.2);
  backdrop-filter: blur(5px);
}

.divider {
  margin: 1.5rem 0;
  position: relative;
  text-align: center;
}

.divider::before,
.divider::after {
  content: '';
  position: absolute;
  top: 50%;
  width: calc(50% - 30px);
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(51, 51, 255, 0.3), transparent);
}

.divider::before {
  left: 0;
}

.divider::after {
  right: 0;
}

.divider span {
  background: rgba(255, 255, 255, 0.9);
  padding: 0 15px;
  color: #4a5568;
  font-size: 0.9rem;
  font-weight: 500;
}

.google-button {
  width: 100%;
  padding: 1rem;
  background: white;
  border: 2px solid rgba(51, 51, 255, 0.2);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
  color: #2d3748;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.google-button:disabled {
  background: rgba(255, 255, 255, 0.8);
  cursor: not-allowed;
  opacity: 0.7;
}

.google-button:not(:disabled):hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: #3333FF;
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(51, 51, 255, 0.1);
}

.google-button img {
  width: 20px;
  height: 20px;
  opacity: 0.8;
}

.learning-path-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #e5e7eb;
  padding-bottom: 10px;
}

.tab {
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 500;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.tab:hover {
  color: #3b82f6;
}

.tab.active {
  color: #3b82f6;
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #3b82f6;
}

.path-content {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.level-card {
  background: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.level-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e5e7eb;
}

.level-header h3 {
  margin: 0;
  font-size: 24px;
  color: #1f2937;
}

.duration {
  padding: 6px 12px;
  background-color: #f3f4f6;
  border-radius: 20px;
  font-size: 14px;
  color: #4b5563;
}

.topics-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.topic {
  background-color: #f9fafb;
  padding: 16px;
  border-radius: 8px;
}

.topic h4 {
  margin: 0 0 12px 0;
  color: #374151;
  font-size: 16px;
}

.topic ul {
  margin: 0;
  padding-left: 20px;
  list-style-type: disc;
}

.topic li {
  color: #6b7280;
  margin-bottom: 6px;
  font-size: 14px;
}

.topic li:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .topics-list {
    grid-template-columns: 1fr;
  }

  .level-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .duration {
    align-self: flex-start;
  }
}

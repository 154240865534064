.video-resources {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  min-height: calc(100vh - 100px);
}

.filters-section {
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filters-section h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.8rem;
}

.filters {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 200px;
}

.filter-group label {
  font-weight: 500;
  color: #666;
  white-space: nowrap;
}

.filter-group select,
.filter-group input {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  transition: all 0.2s ease;
  flex-grow: 1;
}

.filter-group select:hover,
.filter-group input:hover {
  border-color: #999;
}

.filter-group select:focus,
.filter-group input:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.filter-group.search {
  flex-grow: 1;
  min-width: 300px;
}

.filter-group.search input {
  width: 100%;
}

.videos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.video-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.video-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #1a73e8, #34a853);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-card:hover::before {
  opacity: 1;
}

.video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.difficulty-badge {
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
  font-size: 0.85rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.duration {
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 5px;
}

.duration::before {
  content: '⏱';
  font-size: 1rem;
}

.video-card h3 {
  margin: 0 0 15px 0;
  color: #333;
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 600;
}

.video-details {
  flex-grow: 1;
  margin-bottom: 15px;
}

.channel {
  color: #1a73e8;
  font-weight: 600;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.channel::before {
  content: '📺';
  font-size: 1rem;
}

.topic {
  color: #666;
  font-size: 0.9rem;
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.topic::before {
  content: '📚';
  font-size: 1rem;
}

.description {
  color: #666;
  font-size: 0.95rem;
  margin: 12px 0;
  line-height: 1.6;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.tag {
  background: #f0f4f8;
  color: #1a73e8;
  padding: 4px 10px;
  border-radius: 15px;
  font-size: 0.85rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tag:hover {
  background: #e8f0fe;
  transform: scale(1.05);
}

.watch-button {
  display: inline-block;
  width: 100%;
  padding: 12px 20px;
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: none;
  cursor: pointer;
}

.watch-button:hover {
  background-color: #1557b0;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(26, 115, 232, 0.3);
}

.no-videos {
  text-align: center;
  padding: 60px 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.no-videos p:first-child {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

.no-videos p:last-child {
  color: #666;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .video-resources {
    padding: 15px;
  }

  .filters-section {
    padding: 15px;
  }

  .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-group {
    width: 100%;
    min-width: unset;
  }

  .videos-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .video-card {
    margin: 0;
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .video-resources {
    background: #1a1a1a;
  }

  .filters-section,
  .video-card,
  .no-videos {
    background: #2d2d2d;
  }

  .filters-section h2,
  .video-card h3 {
    color: #fff;
  }

  .filter-group label,
  .duration,
  .description,
  .topic {
    color: #bbb;
  }

  .filter-group select,
  .filter-group input {
    background: #3d3d3d;
    border-color: #4d4d4d;
    color: #fff;
  }

  .tag {
    background: #3d3d3d;
    color: #66b3ff;
  }

  .tag:hover {
    background: #4d4d4d;
  }

  .no-videos p:first-child {
    color: #fff;
  }

  .no-videos p:last-child {
    color: #bbb;
  }
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-top: auto;
  background-color: #dc2626;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #b91c1c;
}

.logout-button svg {
  font-size: 1.25rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .logout-button {
    margin: 1rem;
    width: calc(100% - 2rem);
  }
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 2rem;
  background-color: #f7fafc;
  margin-left: 300px; /* Width of the sidebar */
}

.sidebar {
  width: 300px; /* Increased from 250px */
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #1a237e;
  color: white;
  padding: 0;
  z-index: 1000;
}

.sidebar-header {
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.sidebar-nav {
  padding: 1rem 0;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: background-color 0.2s;
}

.sidebar-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.sidebar-link.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border-right: 3px solid white;
}

.sidebar-icon {
  margin-right: 0.75rem;
  font-size: 1.25rem;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-label {
  font-size: 1rem;
  font-weight: 500;
}

.mobile-menu-button {
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
  background: #1a237e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.mobile-open {
    transform: translateX(0);
  }

  .mobile-menu-button {
    display: block;
  }
}

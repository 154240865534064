/* Base container styles */
.shared-profile-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f1f5f9;
  padding: 1rem;
  box-sizing: border-box;
}

.shared-profile-content {
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.5rem;
}

/* Typography */
.shared-profile-title {
  color: #1a237e;
  font-size: clamp(1.25rem, 3vw, 2rem);
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
}

/* Platform usernames section */
.platform-usernames {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  box-sizing: border-box;
}

.platform-usernames h2 {
  font-size: clamp(1.1rem, 2.5vw, 1.5rem);
  color: #1a237e;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}

.platform-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Form elements */
.form-group {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 6px;
  margin: 0;
  box-sizing: border-box;
}

.form-group label {
  min-width: 100px;
  color: #1a237e;
  font-weight: 500;
  padding-right: 1rem;
}

.username-display {
  flex: 1;
  color: #334155;
  font-family: monospace;
  font-size: 0.9rem;
  overflow-wrap: break-word;
  word-break: break-all;
}

/* Stats grid */
.platform-stats-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
}

.platform-card {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.platform-card h3 {
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: #1a237e;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #1a237e;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #334155;
  font-size: 0.9rem;
}

/* Chart section */
.chart-section {
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  box-sizing: border-box;
}

.chart-container {
  width: 100%;
  height: 300px;
  margin-top: 0.5rem;
}

/* Profile header */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
}

.profile-header h1 {
  margin: 0;
  color: #1a237e;
  font-size: 2rem;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
}

.share-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #1a237e;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.share-button:hover {
  background-color: #0d1b60;
}

.share-button.copied {
  background-color: #22c55e;
}

.share-url {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f1f5f9;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #64748b;
}

/* Tablet and desktop styles */
@media (min-width: 768px) {
  .shared-profile-container {
    padding: 2rem;
  }

  .shared-profile-content {
    padding: 1rem;
    gap: 2rem;
  }

  .platform-usernames,
  .platform-card,
  .chart-section {
    padding: 1.5rem;
  }

  .form-group {
    padding: 1rem;
  }

  .platform-stats-grid {
    gap: 1.5rem;
  }

  .chart-container {
    height: 400px;
  }
}

/* Mobile styles */
@media (max-width: 767px) {
  .form-group {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .form-group label {
    width: 100%;
    padding-right: 0;
  }

  .username-display {
    width: 100%;
    padding: 0.25rem 0;
  }

  .platform-stats-grid {
    grid-template-columns: 1fr;
  }

  .stat-item {
    font-size: 0.85rem;
  }

  .profile-header {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .header-actions {
    width: 100%;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  .share-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .share-url {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Small mobile styles */
@media (max-width: 480px) {
  .shared-profile-container {
    padding: 0.5rem;
  }

  .shared-profile-content {
    padding: 0.25rem;
    gap: 1rem;
  }

  .platform-usernames,
  .platform-card,
  .chart-section {
    padding: 0.75rem;
  }

  .form-group {
    padding: 0.5rem;
  }

  .chart-container {
    height: 250px;
  }
}

.contests-container {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.contests-header h2 {
  margin: 0;
  color: #1a237e;
  font-size: 1.8rem;
}

.platform-select {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  min-width: 150px;
  cursor: pointer;
}

.platform-select:focus {
  outline: none;
  border-color: #667eea;
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.1);
}

.contests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.contest-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  transition: transform 0.2s, box-shadow 0.2s;
}

.contest-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contest-platform {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.contest-platform.leetcode {
  background: #faf5ff;
  color: #805ad5;
}

.contest-platform.codeforces {
  background: #e6fffa;
  color: #319795;
}

.contest-platform.codechef {
  background: #fff5f5;
  color: #e53e3e;
}

.contest-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0.5rem 0;
}

.contest-time {
  margin: 1rem 0;
}

.contest-date {
  color: #4a5568;
  font-size: 0.9rem;
}

.time-until {
  color: #667eea;
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.contest-details {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  color: #718096;
  font-size: 0.9rem;
}

.contest-duration,
.contest-difficulty {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.register-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: #667eea;
  color: white;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.register-button:hover {
  background: #5a67d8;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #4a5568;
  font-size: 1.1rem;
}

.error {
  background: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 1rem;
}

.retry-button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  background: #667eea;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
}

.retry-button:hover {
  background: #5a67d8;
}

.no-contests {
  text-align: center;
  color: #4a5568;
  padding: 2rem;
  background: #f7fafc;
  border-radius: 8px;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .contests-container {
    padding: 1rem;
  }

  .contests-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .contests-grid {
    grid-template-columns: 1fr;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  min-height: 100vh;
  background-color: #f5f5f5;
}

.app-container {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.main-content {
  flex: 1;
  padding: 20px;
  min-height: 100vh;
  background-color: #f5f5f5;
  transition: margin-left 0.3s ease;
}

.main-content.with-sidebar {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.page-container {
  max-width: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .main-content.with-sidebar {
    margin-left: 0;
    width: 100%;
  }
  
  .page-container {
    padding: 10px;
  }
}

/* Common styles for all pages */
.page-header {
  margin: 0;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  background: white;
}

.page-header h1 {
  color: #1a237e;
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.page-content {
  padding: 1rem 2rem;
}

/* Form styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #4a5568;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #667eea;
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.1);
}

/* Button styles */
.btn {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-primary {
  background-color: #667eea;
  color: white;
}

.btn-primary:hover {
  background-color: #5a67d8;
}

.btn-secondary {
  background-color: #e2e8f0;
  color: #4a5568;
}

.btn-secondary:hover {
  background-color: #cbd5e0;
}

/* Card styles */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
}

/* Grid layout */
.grid {
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

/* Loading state */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #4a5568;
  font-size: 1.1rem;
}

/* Error state */
.error {
  background: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin: 1rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .page-header,
  .page-content {
    padding: 1rem;
  }

  .page-header h1 {
    font-size: 1.5rem;
  }

  .grid {
    padding: 1rem;
    grid-template-columns: 1fr;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .navbar.visible {
    flex-direction: column;
    gap: 1rem;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .container {
    padding: 0 0.5rem;
  }
}

/* Animation classes */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* Reset default margins and set box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set base font and background */
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.5;
  background-color: #f7fafc;
  color: #2d3748;
}

/* Basic link styling */
a {
  color: #4299e1;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

/* Basic button styling */
button {
  cursor: pointer;
  border: none;
  outline: none;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

/* Basic input styling */
input, select, textarea {
  font-family: inherit;
  font-size: inherit;
}

/* Responsive container */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Utility classes */
.text-center {
  text-align: center;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar {
  background-color: #1f2937;
  padding: 1rem;
  display: none; /* Hide by default */
}

.navbar.visible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.nav-brand {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-links a {
  color: #e5e7eb;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.2s;
}

.nav-links a:hover {
  color: white;
}

.loading-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  color: white;
}

.loader {
  border: 4px solid #f3f4f6;
  border-top: 4px solid #3b82f6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

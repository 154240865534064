.resources-container {
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resources-header {
  margin-bottom: 2rem;
}

.resources-header h2 {
  color: #1a237e;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.filters {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.filter-select {
  padding: 0.5rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.95rem;
  min-width: 150px;
  cursor: pointer;
}

.filter-select:focus {
  outline: none;
  border-color: #667eea;
  box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.1);
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 1.5rem;
}

.resource-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  transition: transform 0.2s, box-shadow 0.2s;
}

.resource-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resource-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.resource-type {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.resource-type.video {
  background: #ebf8ff;
  color: #3182ce;
}

.resource-type.article {
  background: #f0fff4;
  color: #38a169;
}

.difficulty-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
}

.difficulty-badge.beginner {
  background: #e6fffa;
  color: #319795;
}

.difficulty-badge.intermediate {
  background: #faf5ff;
  color: #805ad5;
}

.difficulty-badge.advanced {
  background: #fff5f5;
  color: #e53e3e;
}

.resource-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0.5rem 0;
}

.resource-creator {
  color: #4a5568;
  font-size: 0.95rem;
  margin: 0.5rem 0;
}

.resource-topic {
  color: #4a5568;
  font-size: 0.95rem;
  margin: 0.5rem 0;
}

.resource-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #4a5568;
}

.stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.label {
  color: #718096;
  font-weight: 500;
}

.resource-description {
  margin: 1rem 0;
  font-size: 0.95rem;
  color: #4a5568;
}

.resource-description p {
  margin-top: 0.25rem;
}

.ai-recommendation {
  margin: 1rem 0;
  padding: 1rem;
  background: #f7fafc;
  border-radius: 8px;
  border-left: 4px solid #4299e1;
}

.ai-recommendation .label {
  color: #2b6cb0;
  font-weight: 600;
}

.ai-recommendation p {
  margin-top: 0.5rem;
  color: #4a5568;
  font-size: 0.95rem;
  line-height: 1.5;
}

.prerequisites {
  margin: 1rem 0;
}

.prereq-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.prereq-tag {
  background: #f7fafc;
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.85rem;
  color: #4a5568;
}

.key-points {
  margin: 1rem 0;
}

.key-points ul {
  margin-top: 0.5rem;
  padding-left: 1.5rem;
}

.key-points li {
  color: #4a5568;
  font-size: 0.95rem;
  margin-bottom: 0.25rem;
}

.resource-button {
  display: block;
  width: 100%;
  padding: 0.75rem;
  background: #667eea;
  color: white;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 500;
  margin-top: 1.5rem;
  transition: background-color 0.2s;
}

.resource-button:hover {
  background: #5a67d8;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #4a5568;
  font-size: 1.1rem;
}

.error {
  background: #fed7d7;
  color: #c53030;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 1rem;
}

.retry-button {
  display: block;
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  background: #667eea;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.95rem;
}

.retry-button:hover {
  background: #5a67d8;
}

@media (max-width: 768px) {
  .resources-container {
    padding: 1rem;
  }

  .resources-grid {
    grid-template-columns: 1fr;
  }

  .filters {
    flex-direction: column;
  }

  .filter-select {
    width: 100%;
  }
}

.problems-container {
  padding: 20px;
}

.filters-section {
  margin-bottom: 30px;
  background: white;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filters-section h2 {
  margin-bottom: 25px;
  color: #333;
  font-size: 1.5rem;
}

.filter-controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-group label {
  font-weight: 500;
  color: #555;
  font-size: 1rem;
}

.difficulty-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.difficulty-button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
  background-color: #f0f0f0;
  color: #333;
}

.difficulty-button.active {
  background-color: var(--button-color);
  color: var(--text-color);
  transform: scale(1.05);
}

.difficulty-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.topic-select {
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  background-color: white;
  transition: all 0.2s;
}

.topic-select:hover {
  border-color: #2575fc;
}

.topic-select:focus {
  outline: none;
  border-color: #2575fc;
  box-shadow: 0 0 0 3px rgba(37, 117, 252, 0.1);
}

.problems-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.problem-card {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.problem-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.problem-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.problem-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
  flex: 1;
  margin-right: 10px;
}

.platform-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 500;
}

.platform-badge.leetcode {
  background-color: #ffa116;
  color: white;
}

.platform-badge.codeforces {
  background-color: #1f8acb;
  color: white;
}

.platform-badge.codechef {
  background-color: #4a90e2;
  color: white;
}

.problem-details {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.difficulty-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
}

.difficulty-badge.easy {
  background-color: #00b8a3;
  color: white;
}

.difficulty-badge.medium {
  background-color: #ffa116;
  color: white;
}

.difficulty-badge.hard {
  background-color: #ff375f;
  color: white;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  color: #666;
}

.solve-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2575fc;
  color: white;
  text-align: center;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 15px;
  transition: background-color 0.2s;
}

.solve-button:hover {
  background-color: #1a5fd1;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2575fc;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  text-align: center;
  padding: 30px;
}

.error-message {
  color: #ff375f;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .problems-container {
    padding: 10px;
  }

  .filters-section {
    padding: 15px;
  }

  .difficulty-buttons {
    flex-direction: column;
    width: 100%;
  }

  .difficulty-button {
    width: 100%;
    text-align: center;
  }
}
